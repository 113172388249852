.slideshow-wrap {
    position: relative;
    //@extend .bg-color-black;
    #layerslider { display: block; }
    #layerslider-mobile { display: none; }
    @media screen and (max-width: $size-slider-breakpoint) {
        #layerslider { display: none; }
        #layerslider-mobile { display: block; }
    }

    .slideshow-next {
        right: 50px;
    }
    .slideshow-prev {
        left: 50px;
    }
    .subtitle {
        color: $color-white;
        letter-spacing: 0.3rem;
        text-transform: uppercase;
        font-size: $font-size-24;
        text-align: center;
        padding-bottom:0.5rem;
    }
    .title {
        @extend .font-2-normal;
        text-transform: uppercase;
        color: $color-primary;
        font-size: $font-size-50;
        text-align: center;
    }
    .ls-l  {
        width: 100%;
        background: rgba(0, 0, 0, 0.719);
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    .ls-l a {
        width: auto;
    }

    .caption {
        position: absolute;
        top: 50%;
        left: 50%;
    }

    @media screen and (max-width: $size-xs-max) {
        .ls-l a {
            font-size: $font-size-10;
            border-width: 1px;
            padding: 0.25rem;
        }
    }
    @media screen and (max-width: $size-slider-breakpoint) {
        //.title {
        //    font-size: 26px;
        //}
        //.subtitle {
        //    font-size: 16px;
        //}

    }

    div.text-box-content {
        width: 100%;        
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0;
        margin-top: -0vw;        

        div.box-text {
            width: 35vw;
            min-width: 450px; 
            padding: 15px;
            display: flex;
            background: $bg-color-nav;
            justify-content: center;
            align-items: center;
            margin-right: -25px;
            @media screen and (max-width: $size-max-m-1) {
                min-width: 400px; 
                padding: 10px;
                transition: .5s;
                @media screen and (max-width: $size-max-m-2) {
                    min-width: 350px; 
                    padding: 7px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-3) {
                        min-width: 300px; 
                        transition: .5s;
                    }
                }
            }

            div.content-text {                
                width: 100%;
                height: 100%;
                min-height: 200px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border: 2px solid $color-white;
                padding: 0 2vw;
                transition: .5s;
                @media screen and (max-width: $size-max-2) {
                    padding: 10px 15px;
                    min-height: auto;
                    transition: .5s;
                    @media screen and (max-width: $size-max-3) {
                        height: 100px;
                        padding: 0 15px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-1) {
                            min-height: 100px;
                            padding: 0;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-2) {
                                border: 1px solid $color-white;
                                height: 80px;
                                min-height: 80px;
                                padding: 0;
                                transition: .5s;
                            }
                        }
                    }
                }

                h3 {
                    width: 55%;
                    text-transform: none;
                    font-family: $font-family-2;
                    font-weight: 400;
                    font-size: 32px;
                    line-height: 40px;
                    text-align: right;
                    transition: .5s;
                    @media screen and (max-width: $size-max-2) {
                        font-size: 25px;
                        line-height: 30px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-3) {
                            font-size: 20px;
                            line-height: 25px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-1) {
                                font-size: 20px;
                                line-height: 25px;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-2) {
                                    font-size: 18px;
                                    line-height: 22px;
                                    transition: .5s;
                                    @media screen and (max-width: $size-max-m-3) {
                                        font-size: 16px;
                                        line-height: 20px;
                                        transition: .5s;
                                    }
                                }
                            }
                        }
                    }
                }

                div.content-logo {
                    width: 45%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        transition: .5s;
                        @media screen and (max-width: $size-max-2) {
                            width: 75px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-3) {
                                width: 65px;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-1) {
                                    width: 65px;
                                    transition: .5s;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

