// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
/* purgecss start ignore */
@import '../vendors/font-awesome/font-awesome';
@import '../vendors/owl-carousel2/owl.carousel';
@import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
// @import '../vendors/parallax-scroll/normalize';
// @import '../vendors/parallax-scroll/main';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    .parallax, .parallax-slow, .parallax-fast {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

section.section-01-index {
    display: flex;
    justify-content: center;
    align-items: center;
    background: url(../images/section_02_bg.jpg) no-repeat no-repeat center center;
    background-size: cover;

    div.container-fluid {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 95px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        transition: .5s;
        @media screen and (max-width: $size-max-m-1) {
            padding: 15px;
            flex-direction: column;
            transition: .5s;
        }

        div.left-side-s-1,
        div.right-side-s-1 {
            min-height: 550px;
            transition: .5s;
        }

        div.left-side-s-1 {
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            transition: .5s;
            div.fixed-position {
                width: 100%;
                position: fixed;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                transition: .5s;
            }
            .box {
                //width: 100px;
                //height: 100px;
                line-height: 100px;
                text-align: center;
                font-size: 160%;
                color: #fff;
                position: absolute;
                background: #ff8330;
            }
            @media screen and (max-width: $size-max-m-1) {
                width: 100%;
                align-items: center;
                transition: .5s;
                @media screen and (max-width: $size-max-2) {
                    transition: .5s;
                }
            }



            div.content-img {
                height: 28vw;
                width: 100%;
                background-attachment: fixed;
                background-size: auto;
                background-position: left;
                padding: 25px;
                padding-left: 0;
                &::after {
                    content: '';
                    width: 40.5vw;
                    height: 22vw;
                    background: transparent;
                    position: absolute;
                    margin-top: 20px;
                    border: 0 solid #fff;
                }

                div.border-frame {
                    width: 100%;
                    height: 25.5vw;
                    transition: .5s;
                    border: 2px solid $color-white;
                    padding-left: 0;
                    border-left: 0;
                    display: none;
                }
            }

            div.bottom-div {
                width: 100%;
                margin-top: 25px;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: flex-end;
                padding-right: 0;
                margin-top: 25px;
                transition: .5s;
                @media screen and (max-width: $size-max-m-1) {
                    align-items: center;
                    justify-content: center;
                    transition: .5s;
                }

                h3 {
                    width: 100%;
                    text-align: right;
                    font-size: 37px;
                    font-weight: 800;
                    font-family: $font-family-2;
                    color: $color-accent-2;
                    text-transform: none;
                    transition: .5s;
                    @media screen and (max-width: $size-max-2) {
                        font-size: 34px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-3) {
                            font-size: 30px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-1) {
                                text-align: center;
                                font-size: 25px;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-2) {
                                    font-size: 22px;
                                    transition: .5s;
                                    @media screen and (max-width: $size-max-m-3) {
                                        font-size: 20px;
                                        transition: .5s;
                                    }
                                }
                            }
                        }
                    }
                }

                h4 {
                    width: 100%;
                    text-align: right;
                    font-size: 27px;
                    font-weight: 500;
                    font-family: $font-family-1;
                    color: $color-accent-1;
                    text-transform: none;
                    margin-top: 15px;
                    @media screen and (max-width: $size-max-2) {
                        font-size: 24px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-3) {
                            font-size: 22px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-1) {
                                text-align: center;
                                font-size: 20px;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-2) {
                                    font-size: 18px;
                                    transition: .5s;
                                    @media screen and (max-width: $size-max-m-3) {
                                        font-size: 16px;
                                        transition: .5s;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        div.right-side-s-1 {
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-end;
            padding-left: 10vw;
            @media screen and (max-width: $size-max-m-1) {
                width: 100%;
                padding: 15px;
                transition: .5s;
                margin-top: 65px;
            }

            div.box-right {
                width: 100%;
                padding: 10px 20px;
                background: $bg-color-nav;
                margin-bottom: 45px;
                padding-right: 0;
                border-right: 0;

                @media screen and (max-width: $size-max-m-1) {
                    padding: 10px 20px;
                    transition: .5s;
                }

                h3 {
                    height: 100%;
                    width: 100%;
                    font-size: 50px;
                    text-align: right;
                    font-weight: 200;
                    line-height: 55px;
                    border: 2px solid $color-white;
                    font-family: $font-family-2;
                    text-transform: none;
                    padding: 2vw 4vw;
                    transition: .5s;
                    @media screen and (max-width: $size-max-2) {
                        font-size: 40px;
                        line-height: 50px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-3) {
                            padding: 1vw 2vw;
                            font-size: 30px;
                            line-height: 40px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-4) {
                                font-size: 25px;
                                line-height: 32px;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-1) {
                                    text-align: center;
                                    transition: .5s;
                                }
                            }
                        }
                    }
                }
            }

            div.content-paragraph {
                padding-right: 8vw;
                transition: .5s;
                @media screen and (max-width: $size-max-m-1) {
                    padding: 15px;
                    transition: .5s;
                }
                p {
                    font-size: 18px;
                    line-height: 25px;
                    font-weight: 500;
                    color: $color-grey;
                    margin-bottom: 25px;
                    @media screen and (max-width: $size-max-m-1) {
                        text-align: justify;
                        transition: .5s;
                        font-size: 16px;
                        font-weight: 300;
                        transition: .5s;
                    }
                }
                li {
                  font-size: 18px;
                  line-height: 25px;
                  font-weight: 500;
                  color: $color-grey;
                }

                .green-bold {
                    font-family: $font-family-2;
                    color: $color-accent-2;
                    font-size: 24px;
                    font-weight: 700;
                    line-height: 30px;
                    transition: .5s;
                }
            }

        }
    }
}

section.section-01-index.one-1 {
    div.container-fluid {
        background:
            //url(../images/section_01_img_01.png) left 0px top 0px no-repeat,
            url(../images/section_01_vines.png) no-repeat top right;
        div.left-side-s-1 {
            div.content-img {
                height: 28vw;
                background: url(../images/section_01_img_parallax.jpg) no-repeat;
                background-attachment: fixed;
                background-size: 50%;
                background-position: left;
                @media screen and (max-width: $size-max-m-1) {
                    height: 75vw;
                    background-size: 98%;
                    //background-position: center;
                    transition: .5s;
                }
            }
        }
    }
}

section.section-01-index.twon-2 {
    div.container-fluid {
        background:
            url(../images/section_02_vines.png) no-repeat top right;
        div.left-side-s-1 {
            div.content-img {
                height: 28vw;
                background: url(../images/section_02_img_parallax.jpg) no-repeat;
                background-attachment: fixed;
                background-size: 50%;
                background-position: left;
                @media screen and (max-width: $size-max-m-1) {
                    height: 75vw;
                    background-size: 98%;
                    background-position: center;
                    transition: .5s;
                }
            }
        }
    }
}

section.section-01-index.three-3 {
    div.container-fluid {
        background:
            //url(../images/section_01_img_01.png) left 0px top 0px no-repeat,
            url(../images/section_03_vines.png) no-repeat top right;
        div.left-side-s-1 {
            div.content-img {
                height: 28vw;
                background: url(../images/section_03_img_parallax.jpg) no-repeat;
                background-attachment: fixed;
                background-size: 50%;
                background-position: left;
                @media screen and (max-width: $size-max-m-1) {
                    height: 75vw;
                    background-size: 98%;
                    background-position: center;
                    transition: .5s;
                }
            }
        }
    }
}

section.section-02-index {
    display: flex;
    justify-content: center;
    align-items: center;
    background: url(../images/section_04_bg_parallax.jpg) no-repeat center center;
    background-attachment: fixed;
    background-size: cover;

    div.container-fluid {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 125px;

        div.body-section {
            width: 90%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 45px 0;
            padding-bottom: 0;
            transition: .5s;
            @media screen and (max-width: $size-max-2) {
                width: 95%;
                transition: .5s;
                @media screen and (max-width: $size-max-3) {
                    width: 98%;
                    transition: .5s;
                    @media screen and (max-width: $size-max-4) {
                        width: 100%;
                        transition: .5s;
                    }
                }
            }

            div.top-list,
            div.bottom-list {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
            }

            div.box-wine {
                width: 20%;
                min-width: 300px;
                margin: 5vw 2vw;
                transition: .5s;
                &:hover {
                    div.bg-2second {
                        img {
                            top: -135px;
                            width: 105px;
                            transition: .5s;
                        }
                    }
                }
                @media screen and (max-width: $size-max-3) {
                    margin: 85px 45px;
                    transition: .5s;
                }
                div.bg-1-first {
                    padding: 25px;
                    background: $color_main;
                }

                div.bg-2second {
                    width: 120%;
                    min-height: 85px;
                    padding: 15px;
                    display: flex;
                    background: $color-accent-1;
                    justify-content: flex-end;
                    align-items: center;
                    border: 2px solid $color-white;
                    padding-right: 2vw;
                    img {
                        width: 92px;
                        position: absolute;
                        transition: .5s;
                        left: 35px;
                        top: -115px;
                    }

                    h3 {
                        font-size: 28px;
                        font-weight: 700;
                        font-family: $font-family-2;
                        color: $color-white;
                        text-transform: none;
                        transition: .5s;
                        @media screen and (max-width: $size-max-2) {
                            font-size: 25px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-3) {
                                font-size: 22px;
                                transition: .5s;
                                @media screen and (max-width: $size-max-m-1) {
                                    font-size: 20px;
                                    transition: .5s;
                                }
                            }
                        }
                    }
                }
            }
        }

        div.footer-section {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 45px 0;
            margin-top: 0;
            width: 100%;

            div.content-botton {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}

section.section-logos {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 75px 0;

    div.container-fluid {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        div.header-section {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;

            h3 {
                font-family: $font-family-2;
                color: $color-accent-2;
                text-transform: none;
                font-weight: 600;
                font-size: 24px;
            }
        }

        div.body-section {
            margin-top: 45px;
            max-height: 300px;
            overflow: hidden;
        }
    }

    ///// Controler buttons
    div.content-controler {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 35px;
        a {
            button {
                text-align: center;
                border-radius: 1px;
                color: $color-white;
                padding: 10px 25px;
                font-weight: 500;
                cursor: pointer;
                font-size: 16px;
                transition: .5s;
                margin: 0 15px;
                width: 115px;
                border: 0;
                &:hover {
                    background: $color-accent-3;
                    transition: .5s;
                }
            }
        }
        a.clicked-btn {
            background: $color-accent-3;
            transition: .5s;
        }
    }
}


a.btn-border-full {
    width: 20vw;
    height: auto;
    min-width: 300px;
    min-height: 75px;
    padding: 5px;
    background: $color-accent-1;
    margin-top: 35px;
    transition: .5s;
    font-weight: 700;
    &:hover {
        background: $color-4;
        transition: .5s;
        span {
            background: $color-accent-1;
            transition: .5s;
        }
    }
    span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        min-height: 75px;
        color: $color-white;
        background: $color-4;
        font-size: 22px;
        text-align: center;
        border: 1px solid $color-white;
        font-family: $font-family-2;
        transition: .5s;
        padding: 15px;
    }
    @media screen and (max-width: $size-xs-max) {
      margin: auto;
    }
}

section.recommended-content-gerable {
	position: absolute;
	z-index: 9999;
	display: flex;
	justify-content: center;
	align-items: center;
	div.container {

		div.modal-PDF-FSB {
			padding: 0;
			justify-content: center;
			align-items: center;
		}

		div.body-content {
			width: 100%;
			background: $color-white;
			padding: 25px;
			@media screen and (max-width: 1455px) {
				padding: 15px;
			}

			p {
				color: #000;
				font-weight: 400;
				font-size: 20px;
                line-height: 32px;
                padding: 25px;
                text-align: center;
				@media screen and (max-width: 1455px) {
                    padding: 15px;
                    @media screen and (max-width: 480px) {
                        font-size: 16px;
                        line-height: 30px;
                    }
                }
			}
		}
	}

	/* The Modal (background) */
	.modal-gerable {
		display: none; /* Hidden by default */
		position: fixed; /* Stay in place */
		z-index: 1; /* Sit on top */
		padding-top: 100px; /* Location of the box */
		left: 0;
		top: 0;
		width: 100%; /* Full width */
		height: 100%; /* Full height */
		overflow: auto; /* Enable scroll if needed */
		background-color: rgb(0,0,0); /* Fallback color */
		background-color: rgba(0,0,0,0.76); /* Black w/ opacity */
	}

	/* Modal Content */
	.modal-content-gerable {
		background-color: #000;
		margin: auto;
		padding: 0;
		border: 0 solid #888;
		width: 60%;
		max-width: 1170px;
		@media screen and (max-width: 1452px) {
			max-width: 100%;
			width: 90%;
		}

		div.header-modal-PDF {
			height: 80px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-left: 20px;
			padding-right: 15px;
            background:
                url(../images/section_01_vines.png) no-repeat top right $color-1;
            background-size: cover;
            transition: .5s;
            @media screen and (max-width: $size-max-3) {
                height: 70px;
                transition: .5s;
                @media screen and (max-width: $size-max-m-4) {
                    height: 60px;
                    transition: .5s;
                }
            }

            h3 {
                color: $color-white;
                text-transform: none;
                font-weight: 500;
                font-size: 24px;
            }

			span {
                color: $color-black;
                font-size: 35px!important;
                opacity: .5;
			}
        }

        div.footer-modal-PDF {
            padding: 10px 15px;
            background: $color-white;
            color: #ccc;
            font-size: 11px;
            width: 100%;
            text-align: right;
        }
	}

	/* The Close Button */
	.close {
		color: #aaaaaa;
		float: right;
		font-size: 28px;
		font-weight: bold;
	}

	.close:hover,
	.close:focus {
		color: #000;
		text-decoration: none;
		cursor: pointer;
	}
}


/** Add this class to any anchor tag so it clear the fixed menu (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

.shadow {
    position: relative;
    &::after {
        content: '';
        z-index: 1;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}


/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or module-menu-centre.php
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';

//@import 'sections/section-bursts';


//@import 'sections/section-bursts-icons';

/*****************************************************/
/* Sections (to use with /modules/module-*-*.php)
/*****************************************************/
@import 'modules/module-slideshow';
//@import 'modules/module-soumission';
@import 'modules/module-header';



/*******************************************************************************
* COMMON TO ALL PAGES
*/


/*****************************************************/
/* INDEX.PHP
/*****************************************************/


/*******************************************************************************
* X PAGE
*/

  //page vignobles

    .section-01-vignobles {
      padding: 150px 0;
      background-image: url('../images/section_01_vines.png'),
                        url('../images/section_01_bg.jpg');
      background-position: right top, left top;
      background-repeat: no-repeat, no-repeat;
      background-size: auto, contain;

      div.container-fluid.row {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 0;
        margin: 0;
        margin-top: 45px;
        flex-wrap: wrap;
      }

      div.full-whidth {
        width: 100%;
      }

      div.left-side,
      div.right-side {
        width: 50%;
        @media screen and (max-width: $size-max-m-1) {
            width: 100%;
            transition: .5s;
            padding-right: 0!important;
            padding-left: 0!important;
        }
      }
      div.left-side {
        padding-right: 4vw;
        @media screen and (max-width: $size-max-m-1) {
          margin: 15px;
          transition: .5s;
        }
      }
      div.right-side {padding-left: 4vw;}

      div.box-bg {
          width: 100%;
          height: 140px;
          padding: 10px 15px;
          background: $bg-color-nav;
          margin-bottom: 45px;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: .5s;          
          @media screen and (max-width: $size-max-m-1) {
              height: 100px;
              padding: 15px!important;
              justify-content: center;
              transition: .5s;
          }

          h3 {
              height: 100%;
              width: 100%;
              font-size: 50px;
              text-align: center;
              font-weight: 200;
              line-height: 55px;
              border: 2px solid $color-white;
              font-family: $font-family-2;
              text-transform: none;
              //padding: 2vw 4vw;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              transition: .5s;
              @media screen and (max-width: $size-max-2) {
                  font-size: 40px;
                  line-height: 50px;
                  transition: .5s;
                  @media screen and (max-width: $size-max-3) {
                      padding: 1vw 2vw;
                      font-size: 30px;
                      line-height: 40px;
                      transition: .5s;
                      @media screen and (max-width: $size-max-4) {
                          font-size: 25px;
                          line-height: 32px;
                          transition: .5s;
                          @media screen and (max-width: $size-max-m-1) {
                              text-align: center!important;
                              border: 2px solid $color-white!important;
                              transition: .5s;
                          }
                      }
                      @media screen and (max-width: $size-max-m-1) {
                        justify-content: center;
                        transition: .5s;
                      }
                  }
              }
          }

          &.right {
            padding-right: 0;                        
            h3 {
              text-align: right;
              border-right: 0;
              padding-left: 8vw;
            }
          }
          &.left {
            padding-left: 0;
            h3 {
              padding-left: 8vw;
              text-align: left;
              border-left: 0;
            }
          }
      }

      div.content-paragraph {

        transition: .5s;
        &.left {
          padding: 0 0 0 8vw;
        }
        &.right {
          padding: 0 8vw 0 0;
        }

        @media screen and (max-width: $size-max-m-1) {
            padding: 15px!important;
            transition: .5s;
        }


        p {
            font-size: 18px;
            font-weight: 500;
            line-height: 25px;
            max-width: 1200px;
            color: $color-grey;
            margin-bottom: 25px;
            text-align: justify;
            @media screen and (max-width: $size-max-m-2) {
              text-align: center;
              transition: .5s;
            }
        }


        h3 {
            width: 100%;
            font-size: 37px;
            font-weight: 800;
            font-family: $font-family-2;
            color: $color-accent-2;
            text-transform: none;
            transition: .5s;
            margin-bottom: 2rem;
            @media screen and (max-width: $size-max-2) {
                font-size: 34px;
                transition: .5s;
                @media screen and (max-width: $size-max-3) {
                    font-size: 30px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-1) {
                        font-size: 25px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-2) {
                          text-align: center;
                            font-size: 22px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-3) {
                                font-size: 20px;
                                transition: .5s;
                            }
                        }
                    }
                }
            }
        }

        h4 {
            width: 100%;
            text-align: right;
            font-size: 27px;
            font-weight: 500;
            font-family: $font-family-1;
            color: $color-accent-1;
            text-transform: none;
            margin-top: 15px;
            @media screen and (max-width: $size-max-2) {
                font-size: 24px;
                transition: .5s;
                @media screen and (max-width: $size-max-3) {
                    font-size: 22px;
                    transition: .5s;
                    @media screen and (max-width: $size-max-m-1) {
                        text-align: center;
                        font-size: 20px;
                        transition: .5s;
                        @media screen and (max-width: $size-max-m-2) {
                            font-size: 18px;
                            transition: .5s;
                            @media screen and (max-width: $size-max-m-3) {
                                font-size: 16px;
                                transition: .5s;
                            }
                        }
                    }
                }
            }
        }
      }


      div.content-img {
          height: 28vw;
          width: 100%;
          background-size: 50%;
          background-attachment: fixed;
          background-repeat: no-repeat;
          background-image: url(../images/section_01_vignoble.jpg);
          padding: 25px;
          padding-left: 0;
          margin-bottom: 2rem;
          &.right {
            background-position: right;
          }
          &.left {
            background-position: left;
          }

          @media screen and (max-width: $size-max-m-2) {
              height: 75vw;
              background-size: 100%;
              //background-position: center;
              transition: .5s;
          }

          div.border-frame {
              width: 100%;
              height: 25.5vw;
              transition: .5s;
              border: 2px solid $color-white;
              padding-left: 0;
              border-left: 0;
              display: none;
          }


      }

      //slogan box for the valeurs section style
      .slogans-box {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        transition: .3s;
        &.vignoble-page {
          justify-content: flex-start;
          padding-left: 7vw;
          transition: .5s;
        }
        & > div {
          flex: 0 1 auto;
          margin: 15px;
          transition: .3s;
          h3 {
            display: flex;
            transition: .3s;
            //white-space: nowrap;
            align-items: center;
            justify-content: center;
            color: $color-primary;
            text-transform: inherit;
            font-family: $font-family-2;
            img {
              max-width: 100px;
              margin-right: 15px;
            }
          }

        }
        @media screen and (max-width: $size-xs-max){
          flex-direction: column;
          & > div {
            flex: 0 1 100%;
          }
        }

      }


    }


    .section-02-vignobles {
      @extend .section-01-vignobles;
      background-image: url('../images/vignoble_section_02_bg.jpg');
      background-position: left top;
      background-size: cover;

      div.content-img {
          background-image: url('../images/section_02_vignoble.jpg');
      }

      div.content-paragraph {
        h4,p {
          color: $color-white;
        }
        h3 {
          color: $color-red-wine;
        }
      }

    }


    section.section-03-vignobles {
      @extend .section-01-vignobles;
      background-image: url('../images/section_01_bg.jpg');
      background-position: left top;
      background-size: cover;
      .slogans-box > div h3 {
        justify-content: flex-start;
        margin: 15px 0;
      }
    }


///page vins et cidres
  .section-01-vins-et-cidres {
    @extend .section-01-vignobles;
    background-image: url('../images/tache_vin_bg_vins_cidres.png');
    background-position: left top;
    background-size: auto 100%;
    &.center-section {
      width: 100%;
      padding: 15px;
      justify-content: center;
      align-items: center;
      transition: .5s;
      div.container-fluid.row {
        padding: 15px;
        justify-content: center;
        transition: .5s;
        div.right-side {
          padding: 0;
          width: 75%;
          @media screen and (max-width: $size-max-m-2) {
            width: 100%;
            transition: .5s;
          }
          div.content-paragraph.right {
            padding: 0;
            transition: .5s;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
    div.box-bg {
      background: rgba(130, 65, 80, 0.88);
    }

    div.content-paragraph {
      h4 {
        color: $color-red-wine;
        text-align: left;
      }
    }
    .content-paragraph {

      &.menu-top-categorie {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        transition: .5s;
        .wine-types {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-wrap: wrap;
          color: $color-primary;
          text-align: center;
          margin-bottom: 30px;
          h3, a {
            width: auto;
            padding: 10px;
            margin: 0;
            font-size: $font-size-26;
            color: $color-green-wine;  
            &.red {
              color: $color-red-wine;
            }  
          }

          h3 {
            transition: .5s;
            @media screen and (max-width: $size-max-2) {
              font-size: $font-size-28;
              transition: .5s;
              @media screen and (max-width: $size-max-4) {
                font-size: $font-size-24;
                transition: .5s;
                @media screen and (max-width: $size-max-m-1) {
                  font-size: $font-size-32;
                  transition: .5s;
                }
              }
            }
          }
          a {
            width: auto;
            padding: 10px;
            margin: 0;
            font-weight: 500;
            font-size: $font-size-26;
            color: $color-green-wine;
            transition: .5s;
            @media screen and (max-width: $size-max-2) {
              font-size: $font-size-22;
              transition: .5s;
              @media screen and (max-width: $size-max-4) {
                font-size: $font-size-26;
                transition: .5s;
                @media screen and (max-width: $size-max-m-1) {
                  font-size: $font-size-28;
                  transition: .5s;
                }
              }
            }
            &:hover {
              color: $color-red-wine; 
              transition: .5s;
            }
            span {
              margin: 0 2px;
              color: $color-green-wine; 
            }            
            &:nth-child(1) {
              color: $color-red-wine;
              span {
                display: none;
              }
            }
            &.red {
              color: $color-red-wine;
            }  
          }
        }
      }
      .flex-title {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        div {margin: 20px 0;}
        div {
          border-left: 1px solid $color-green-wine;
          border-right: 1px solid $color-green-wine;
          &.red {
            border-left: 2px solid $color-red-wine;
            border-right: 2px solid $color-red-wine;
            h3 {
              color: $color-red-wine;
            }
          }
        }


        h3 {
          padding: 0 15px;
          font-size: $font-size-20;
          color: $color-green-wine;
          text-align: center;
          margin: 0;
        }

      }


    }
    @media screen and (max-width: $size-xs-max){
      background-image: none;
      padding: 50px 0;
    }
  }

  .products-section {
    @extend .section-01-vignobles;
    background-image: none;
    @media screen and (max-width: $size-max-m-1) {
      padding: 15px;
      transition: .5s;
    }

    div.box-left-category {
      width: 100%;
      height: 120px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 15px;
      padding-left: 0;
      transition: .5s;
      @media screen and (max-width: $size-max-m-1) {
        height: 90px;
        padding: 10px 15px;
        transition: .5s;
      }
      &.Rouge {
        background: $color-red-wine;
      }
      &.Verte {
        background: $color-green-wine;
      }
      &.Blanc {
        background: $color-grey-light;
      }
      &.Rose {
        background: $color-red-rose;
      }
      &.Cidre {
        background: $color-accent-vert;
      }
      &.Portos {
        background: $color-green-wine;
      }
      &.Mistelles {
        background: $color-green-wine;
      }
      h3.title-category-box {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;        
        text-transform: none;
        font-size: $font-size-32;
        justify-content: flex-start;              
        border: 2px solid $color-white;
        font-family: $font-family-2;
        color: $color-black;
        padding-left: 8vw;
        transition: .5s;  
        border-left: 0; 
        @media screen and (max-width: $size-max-m-1) {
          justify-content: center;
          border: 2px solid $color-white;
          transition: .5s;
          padding: 15px;
        }     
      }
    }

    div.content-paragraph {

      .wine-types {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        color: $color-primary;
        text-align: center;
        margin-bottom: 30px;
        h3 {
          width: auto;
          padding: 10px;
          margin: 0;
          font-size: $font-size-26;
          color: $color-green-wine;

          &.red {
            color: $color-red-wine;
          }

        }
      }

      h3 {font-size: $font-size-30;}

      h3, h4 {
        text-align: left;
        color: $color-grey;
        font-family: $font-family-2;
        font-weight: 700;
        margin: 20px 0 15px 0;
        & .red , & .Rouge {
          color: $color-red-wine;
        }
        & .green, & .Verte {
          color: $color-green-wine;
        }
        & .white , & .Blanc {
          color: $color-grey-light;
        }
        & .Rose {
          color: $color-red-rose;
        }
        & .Cidre {
          color: $color-accent-vert;
        }
        & .Portos {
          color: $color-green-wine;
        }
        & .Mistelles {
          color: $color-green-wine;
        }
        
        & .prix {float: right;}
      }

      img.img-product {
        margin: auto;
      }
      .btn-product {
        margin: auto;
      }

    }
    .separator {
      margin: 4rem 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      & > div {
        margin: 0 1vw;
        span {
          font-size: 3.5vw;
          transition: .5s;
        }
      }
      &.Rouge {
        span {color: $color-red-wine;}        
      }
      &.Verte {
        span {color: $color-green-wine;}      
      }
      &.Blanc {
        span {color: $color-grey-light;}        
      }
      &.Rose {
        span {color: $color-red-rose;}        
      }
      &.Cidre {
        span {color: $color-accent-vert;}        
      }
      &.Portos {
        span {color: $color-green-wine;}        
      }
      &.Mistelles {
        span {color: $color-green-wine;}        
      }
    }
    @media screen and (max-width: $size-sm-max){
      div.content-paragraph {
        ul {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          transition: .5s;
        }
        h3, h4, p {
          text-align: center;

          & .prix {
            float: none;
          }

        }
      }
    }
  }


  // section formulaire de demande
  .section-form {
    padding: 50px 0;
    text-align: center;
    h3 {
      font-size: $font-size-30;
      text-align: center;
      color: $color-black;
      margin-bottom: 20px;
    }
  }
  .mfp-iframe-holder .mfp-content {
    min-height: 450px;
  }


/******************************************************************************/
//page autocueillette
.section-01-autocueillette {
  @extend .section-01-vignobles;
  div.content-paragraph {
    h3, h4 {
      text-align: left;
      font-size: $font-size-30;
      font-family: $font-family-2;
      font-weight: 700;
    }

    h4 {
      width: 100%;
      text-align: right;
      font-size: 27px;
      font-weight: 500;
      font-family: $font-family-1;
      color: $color-accent-1;
      text-transform: none;
      margin-top: 15px;
      @media screen and (max-width: $size-max-2) {
          font-size: 24px;
          transition: .5s;
          @media screen and (max-width: $size-max-3) {
              font-size: 22px;
              transition: .5s;
              @media screen and (max-width: $size-max-m-1) {
                  text-align: center;
                  font-size: 20px;
                  transition: .5s;
                  @media screen and (max-width: $size-max-m-2) {
                      font-size: 18px;
                      transition: .5s;
                      @media screen and (max-width: $size-max-m-3) {
                          font-size: 16px;
                          transition: .5s;
                      }
                  }
              }
          }
      }
  }

  }
  div.content-img {
    background-image: url('../images/section_01_autocueillette.jpg');
  }
  .slogans-box {
    //justify-content: flex-start;
    div {
      min-width: 20%;
      h3 {text-align: left;}
    }    
  }

  div.list-type-pommes {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 7.4vw;
    transition: .5s;
    @media screen and (max-width: $size-max-m-1) {
      flex-wrap: wrap;
      flex-direction: row;
      padding-left: 1.4vw;
      transition: .5s;
    }
    div {
      width: 20%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: .5s;
      @media screen and (max-width: $size-max-m-1) {
        width: 30%;
        transition: .5s;
        @media screen and (max-width: $size-max-m-2) {
          width: 45%;
          transition: .5s;
        }
      }
      img {
        width: 50%;
        transition: .5s;
      }
      span {
        width: 50%;
        font-weight: 600;
        color: $color-green-wine;
        font-family: $font-family-2;
        font-size: $font-size-24;
        margin-left: 10px;        
      }
    }
  }
}

.section-02-autocueillette {

  @extend .section-02-vignobles;
  div.content-img {
    background-image: url('../images/section_02_autocueillette.jpg');
  }

  div.content-paragraph {
    h4 {
      text-align: center;
      color: $color-accent-vert;
      font-family: $font-family-2;
      font-weight: 700;
    }

  }
  .slogans-box {
    align-items: flex-start;;
    justify-content: flex-start;
    max-width: 1200px;
    margin: auto;
    div {
      display: flex;
      min-width: 45%;
      h3 {color: $color-white;}
    }
    @media screen and (max-width: 1100px){
      flex-direction: column;
      max-width: 500px;
    }
  }
}

.section-03-autocueillette {
  @extend .section-01-vignobles;
  background-image: url('../images/section_01_vines.png');
  background-position: top right;
  div.content-paragraph {
    h4 {
      text-align: left;
      color: $color-grey;
      font-weight: 700;
      font-size: $font-size-30;
      margin-bottom: 30px;
      @media screen and (max-width: $size-xs-max){
        text-align: center;
      }
      &.titel-list-proposition {
        color: $color-red-wine;
      }
    }
  }
  

  .list-proposition {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 45px;
    transition: .5s;
    div.item-list {
      width: 25%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      transition: .5s;
      @media screen and (max-width: $size-max-m-1) {
        width: 45%;
        transition: .5s;
        @media screen and (max-width: $size-max-m-2) {
          width: 95%;
          justify-content: center;
          transition: .5s;
        }
      }
      h3 {
        transition: .5s;
        @media screen and (max-width: $size-max-m-2) {
          width: 100%;
          text-align: center;
          transition: .5s;
        }
        span {
          margin-right: 10px;
        }
      }
    }
  }

}


/******************************************************************************/
//page autocueillette
/******************************************************************************/

 section.section-01-forfaits {
   @extend .section-01-vins-et-cidres;
 }

section.section-02-forfaits {
  .section-text-images {
    display: flex;
    justify-content: center;
    align-items: stretch;

    h3 {
      padding: 2rem 0;
      color: $color-red-wine;
      font-family: $font-family-2;
    }
    h4, li {
      line-height: 1.3;
      font-weight: 700;
      font-family: $font-family-2;
      color: $color-grey;
      font-size: $font-size-22;
    }
    p {
      font-weight: 700;
      color: $color-grey;
      line-height: 1.5;
      font-size: $font-size-20;
      padding: 2rem 0;
    }
    .red {
      color: $color-red-wine;
      font-size: 1.3rem;
    }

    div.text-box-01 {
      background-image: url('../images/section_01_forfait01.jpg');
      background-position: center;

      justify-content: center;
      background-size: cover;
      flex-direction: column;
      display: flex;
    }

    div.text-box-02 {
      background-image: url('../images/section_01_forfait02.jpg');
      background-position: center;
      background-size: cover;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 4rem;
      @media screen and (max-width: $size-xs-max){
        padding-left: 15px;
      }
    }
  }
  a.btn-border-full {
    margin-left: auto;
    margin-top: 30px;
  }

  .separator {
    padding: 4rem 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    & > div {
      margin: 0 1vw;
      span {
        font-size: 4.5vw;
        transition: .5s;
      }
    }
  }

}


section.section-03-forfaits {
  @extend .section-02-vignobles;
  div.content-img {
    background-image: url('../images/section_02_forfaits_photo.jpg');
  }
  div.custom-paragraphs {

    h3 {
      color: $color-accent-vert;
      font-family: $font-family-2;
      font-size: $font-size-30;
      padding: 30px 50px;
      @media screen and (max-width: $size-xs-max) {
        padding: 30px 0;
        text-align: center;
      }
    }
    p {
      font-size: 18px;
        font-weight: 400;
        line-height: 25px;
        max-width: 1200px;
        color: $color-white;
        margin-bottom: 25px;
        padding-left: 50px;
        &::before{
          content: '';
          position: absolute;
          left: 5px;
          width: 50px;
          height: 50px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: 100% 100%;
        }
        @media screen and (max-width: $size-max-m-1) {
          font-size: 16px;
          font-weight: 300;
          transition: .5s;
        }

        &.ring::before {background-image: url('../images/point-vino02.jpg');}
        &.tash::before {background-image: url('../images/point-vino03.jpg');}
        &.ring-tash::before {background-image: url('../images/point-vino01.jpg');}

        @media screen and (max-width: $size-max-m-2) {
          text-align: center;
          transition: .5s;
        }
    }
  }

}


/*******************************************************************************
* page salle de reception
*******************************************************************************/
section.section-01-salle-reception {
  @extend .section-01-vignobles;
  div.content-paragraph {
    li {
        font-size: 18px;
        font-weight: 700;
        font-family: $font-family-2;
        line-height: 25px;
        color: $color-grey;
        line-height: 1.2;
        margin-bottom: 15px;
        @media screen and (max-width: $size-max-m-1) {
          font-size: 16px;
          font-weight: 300;
          transition: .5s;
        }
      }
  }
  div.right-side {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-top: -8vw;
    transition: .5s;
    @media screen and (max-width: $size-max-2) {
      margin-top: -4vw;
      transition: .5s;
      @media screen and (max-width: $size-max-m-1) {
        margin-top: 0vw;
        transition: .5s;
        justify-content: center;
        align-items: center;
        padding: 15px;
      }
    }
    div.content-img.right {
      @media screen and (max-width: $size-max-m-1) {
        background-position: center;
        padding: 15px;
        @media screen and (max-width: $size-max-m-2) {
          background-size: 95%;
          background-position: center;
          padding: 15px;
        }
      }
    }
  }
  div.content-img {
    &:nth-child(1) {
      background-image: url('../images/section_01_salle_de_reception01.jpg');
      background-attachment: inherit;
      background-size: auto;
    }
    &:nth-child(2) {
      background-image: url('../images/section_01_salle_de_reception02.jpg');
      background-attachment: inherit;
      background-size: auto;
    }
  }
}

section.section-02-salle-reception {
  @extend .section-02-vignobles;
  div.content-img {
    background-image: url('../images/section_02_salle_de_reception01.jpg');
    //background-size: cover;
  }
}

section.section-03-salle-reception {
  @extend .section-02-vignobles;
  background-image: url('../images/salle_de_reception_bg_sect02.jpg');
  div.content-paragraph {
    li {
        font-size: $font-size-26;
        font-weight: 700;
        font-family: $font-family-2;
        line-height: 25px;
        color: $color-white;
        line-height: 1.2;
        margin-bottom: 15px;
      }
      @media screen and (max-width: $size-xs-max){
        ul {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 0;
        }
      }
  }

}

section.section-04-salle-reception {
  @extend .section-03-autocueillette;
}

/*******************************************************************************
* Page Formulaire de Commande
*******************************************************************************/
section.form {
  padding: 50px 0;
  div.title-box {
    padding: 50px 0;
    h3.title {
      font-family: $font-family-2;
      color: $color-accent-2;
      font-style: $font-size-30;
      text-align: center;
    }
  }
  // form-buttoms

  .g-recaptcha > div,
  button.btn {
    display: block;
    margin: auto;
  }
}

/*******************************************************************************
* Page Coordonnees
*******************************************************************************/
section.section-01-coordonnes {
  @extend .section-01-vignobles;
  background-image: url(../images/section_01_vines.png);
  background-position: right top;
  background-repeat: no-repeat;
  background-size: auto;

  .container-fluid.row {
    padding-top: 50px!important;
    padding-bottom: 50px!important;
  }

  div.box-bg {
    background: $color-accent-2;
  }

  div.left-side, div.text-box {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  div.text-box > div {
      width: 370px;
  }
  h3.firstHeading {
    color: $color-grey;
  }
  h4 {
    color: $color-grey;
    font-size: $font-size-24;
    font-weight: 700;
    font-family: $font-family-2;
    margin-bottom: 30px;
  }
  p {
    color: $color-grey;
    font-size: $font-size-20;
    font-family: $font-family-2;
  }
  a {
    font-family: $font-family-2;
    font-weight: 700;
    &.phone {
      font-size: $font-size-30;
    }
    &:hover {
      color: $color-grey;
    }
  }

  // form-buttoms

  .g-recaptcha > div,
  button.btn {
    display: block;
    margin: auto;
  }

  @media screen and (max-width: $size-xs-max) {
    .map {
      margin: 30px 0;
    }
  }
}

/*******************************************************************************
* PAGE GALERIE D'IMAGES
*******************************************************************************/
section.galerie-section {
  padding: 50px 0;
  div.row {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
  }

  h3 {
    padding: 10px 0;
    color: $color-grey;
    font-size: $font-size-22;
    font-family: $font-family-2;
  }
  p {
    padding: 10px 0;
    color: $color-grey;
    line-height: 1.5;
    font-size: $font-size-18;
    font-family: $font-family-2;
  }
  .item {
    width: 30%;
    margin: 5px;
    transition: .5s;
    div.image {
      position: relative;
      .overlay {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        transition: .5s;
        position: absolute;
        background: rgba(130, 65, 80, 0.69);
      }
    }
    &:hover {
      div.image {
        .overlay {
          opacity: 1;
          transition: .5s;
        }
      }
    }
  }
  @media screen and (max-width: $size-sm-max) {
    .item {
      width: 40%;
      transition: .5s;
    }
    @media screen and (max-width: $size-xs-max) {
      .item {
        width: 100%;
        max-width: 300px;
        transition: .5s;
      }
    }

  }
}

.pt-0 {
  padding-top: 0px!important;
}

.pb-0 {
  padding-bottom: 0px!important;
}

/*/*************FORM STYLE HERE************/
form {
  input[type=text],
  input[type=tel],
  input[type=phone],
  input[type=email],
  input[type=password],
  select#product, 
  select#objet {
    height: 45px;
    border-radius: 1px;
    transition: .5s;
  }

  .form-group {
    label {
      font-size: 17px;
      font-weight: 500;
    }
  }

  textarea#message {
    resize: vertical;
    border-radius: 1px;
    transition: .5s;
  }

  div.help-block li {
    color: #a94442;
    position: absolute;
    line-height: 25px;
    margin-top: -8px;
    font-size: 14px;
  }
}

div.block-join-file {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  transition: .5s;
}

.btn-fileDoc {
  width: 300px;
  height: 50px;
  display: flex;
  border-radius: 1px;
  align-items: center;  
  justify-content: center;
  background: $color-accent-2;
  color: $color-white;  
  font-weight: 400;
  font-size: 18px;
  cursor: pointer;
  transition: .5s;
  &:hover {
    background: $color-accent-3;
    transition: .5s;
  }
}