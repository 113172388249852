.module-header {
    position: relative;

    .caption {
        position: absolute;
        top: $size-nav-height-mobile;
        left: 0;
        right: 0;
        bottom: 0;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .box-bg {
          background: rgba(0, 0, 0, 0.4);
          padding: 10px 0 10px 10px;
          .border {
            padding: 20px;
            background: rgba(0, 0, 0, 0.7);
            border: 2px solid $color-white;
            border-right: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            & > div {
              padding: 0 10px;
            }

            .icon {
              max-width: 30%;
            }
          }
        }


        .subtitle {
            color: $color-white;
            text-transform: uppercase;
            font-size: $font-size-18;
            text-align: center;
        }
        .title {
            font-family: $font-family-2;
            font-weight: 400;
            text-transform: uppercase;
            color: $color-white;
            font-size: 40px;
            text-align: center;
            transition: .3s;
            @media screen and (max-width: 1400px){
              font-size: 30px;
              transition: .3s;
              @media screen and (max-width: 1200px){
                font-size: 24px;
                transition: .3s;
              }
            }
        }

    }
}
